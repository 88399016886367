// Webpack Imports
import * as bootstrap from 'bootstrap';


let refresh_timer = "";

jQuery(document).ready(function() {


});



jQuery(document).ready(function () {
});
